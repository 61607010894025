const jp = require("jsonpath");
import { _http, _ittp } from "./backend.js";

const gds_url = "/gdsapi/rest/v3/clientservice.json";
const chart_url = "/gdsapi/rest/chart/v2/chartiqservice.json";

export default {
  fetchN6APIKey: async () => {
    const response = await _ittp().post("/apikey");
    return response.data.apikey;
  },
  // returns historical price from csbpd, daily prices
  fetchHistoricalBondPricesD: async (apikey, isin, fromDate, toDate) => {
    const s = new Date(fromDate);
    const e = new Date(toDate);
    const dateDiff = Math.floor((e - s) / (1000 * 60 * 60 * 24));
    const base = "/csbpd/Price/timeseries";

    // limit the results to match the dateDiff value because the api may repeats the same date (with different timestamp) if the limit exceeds the actual time window.
    const params = {
      isin: isin,
      limit: Math.min(dateDiff, 10000),
      from: fromDate,
      to: toDate,
      fields: "id,isin,cusip,asOf,asOfDateTime,bidPrice,midPrice,askPrice",
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${base}?${queryString}`;
    let response = await _ittp(apikey).get(url);
    let quote = [];
    if (response.data) {
      quote = response.data.map((e) => ({
        Date: new Date(e.asOfDateTime ?? e.asOf).toISOString(),
        High: e.askPrice,
        Low: e.bidPrice,
        Close: e.midPrice,
      }));
    }
    quote.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    const uniqueDates = new Set();
    const uniqueData = quote.filter((item) => {
      const d = item.Date.split("T")[0];
      if (uniqueDates.has(d)) {
        return false;
      } else {
        uniqueDates.add(d);
        return true;
      }
    });
    for (let i = 1; i < uniqueData.length; i++) {
      uniqueData[i].Open = uniqueData[i - 1].Close;
    }
    return uniqueData;
  },
  // returns historical price from csbpi, frequency is about a few seconds
  fetchHistoricalBondPricesI: async (apikey, isin, fromDate) => {
    const base = "/csbpi/Price/timeseries";
    const params = {
      isin: isin,
      limit: 10000,
      from: fromDate,
      fields: "id,isin,cusip,asOfDateTime,bidPrice,midPrice,askPrice",
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${base}?${queryString}`;
    let response = await _ittp(apikey).get(url);
    let quote = [];
    if (response.data) {
      quote = response.data.map((e) => ({
        Date: e.asOfDateTime,
        High: e.askPrice,
        Low: e.bidPrice,
        Close: e.midPrice,
      }));
      for (let i = 1; i < quote.length; i++) {
        quote[i].Open = quote[i - 1].Close;
      }
    }
    return quote;
  },
  fetchRealtimeBondPrices: async (apikey, isin, after) => {
    const base = "/csbpi/Price/stream";
    const params = {
      isin: isin,
      limit: 10,
      fields: "id,isin,cusip,asOfDateTime,bidPrice,midPrice,askPrice",
    };

    if (after) {
      params["after"] = after;
    }

    const queryString = new URLSearchParams(params).toString();
    const url = `${base}?${queryString}`;
    const response = await _ittp(apikey).get(url);
    let q = [];
    if (response.data.data) {
      q = response.data.data.map((e) => ({
        Date: e.asOfDateTime,
        High: e.askPrice,
        Low: e.bidPrice,
        Close: e.midPrice,
      }));
    }
    return {
      quote: q,
      after: response.data.after,
    };
  },
  fetchBondSymbols: async (isin) => {
    const inputRequests = [
      "IQ_COMPANY_ID",
      "IQ_COMPANY_NAME",
      "IQ_SECURITY_NAME",
    ].map((e) => ({
      function: "GDSP",
      identifier: isin,
      mnemonic: e,
    }));
    const user = JSON.parse(localStorage.getItem("user"));
    let response = await _http(user).post(gds_url, {
      inputRequests: inputRequests,
    });
    const companyId = jp.query(response.data, "GDSSDKResponse[0]..Row[0]")[0];
    const companyName = jp.query(response.data, "GDSSDKResponse[1]..Row[0]")[0];
    const securityName = jp.query(
      response.data,
      "GDSSDKResponse[2]..Row[0]"
    )[0];
    return {
      companyId: companyId,
      companyName: companyName,
      securityName: securityName,
    };
  },
  fetchBondKeyDevs: async (companyId, startDate, endDate, category = "C5") => {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await _http(user).post(chart_url, {
      inputRequests: [
        {
          type: "KEYDEV_DATA",
          identifier: companyId,
          properties: {
            startDate: startDate,
            endDate: endDate,
            keyDevFilters: category,
          },
        },
      ],
    });
    const keydevs = jp.query(response.data, "GDSSDKResponse.KEY_DEV_DATA")[0];
    return keydevs.map((k) => ({
      category: k.data.category,
      headline: k.data.headline,
      story: k.data.story,
      Date: k.data.timestamp,
    }));
  },
};
