<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th>Announced Date</th>
          <th>Closed Date</th>
          <th>Transaction Type</th>
          <th>Buyer</th>
          <th>Target</th>
          <th>Status</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in this.transactions" v-bind:key="index">
          <td style="width: 10%">{{ item[0] == "Data Unavailable" ? "-" : item[0].format("DD MMM YYYY") }}</td>
          <td style="width: 10%">{{ item[1] == "Data Unavailable" ? "-" : item[1].format("DD MMM YYYY") }}</td>
          <td>{{ item[2] == "Data Unavailable" ? "-" : item[2] }}</td>
          <td>{{ item[3] == "Data Unavailable" ? "-" : item[3] }}</td>
          <td>{{ item[4] == "Data Unavailable" ? "-" : item[4] }}</td>
          <td>{{ item[5] == "Data Unavailable" ? "-" : item[5] }}</td>
          <td class="right">
            {{
              item[6] == "Data Unavailable"
                ? "-"
                : vueNumberFormat(parseFloat(item[6]), { precision: 2 })
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
const jp = require("jsonpath");
const moment = require("moment");
import api from '@/api/dashboard1.js'

export default {
  name: "KeyTransactions",
  data: () => {
    return {
      transactions: []
    }
  },
  methods: {
    render: async function (q) {
      this.transactions.splice(0, this.transactions.length);
      let r = await api.fetchTransactionList(q);
      const transactionIds = jp.query(r, "$..Row[0]");

      r = await api.fetchTransactionDetails(transactionIds);
      const transactionDetails = jp.query(r, "$..Row[0]");
      const chunkSize = transactionDetails.length / transactionIds.length;
      for (let i = 0; i < transactionDetails.length; i += chunkSize) {
        // announced date
        if (transactionDetails[i] != "Data Unavailable") {
          transactionDetails[i] = moment(transactionDetails[i], "MM/DD/YYYY");
        }
        // closed date
        if (transactionDetails[i+1] != "Data Unavailable") {
          transactionDetails[i+1] = moment(transactionDetails[i+1], "MMM DD YYYY h:mmA");
        }
        this.transactions.push(transactionDetails.slice(i, i + chunkSize));
      }
    }
  }
};
</script>
<style scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}

.container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 1em;
}

.container td,
th {
  padding: 10px;
  background-color: #fafafa;
  text-align: left;
  border: 2px solid #ddd;
}

.container td {
  text-align: left;
}

.container thead th {
  text-align: center;
}

.container td {
  background-color: #fefefe;
}

.container td.right {
  text-align: right;
}
</style>