import { CIQ } from "chartiq/js/components";
import api from "@/api/dashboard6";
const moment = require("moment");

CIQ.SPGI = CIQ.SPGI || function () {};

CIQ.SPGI.KeyDevMarker = function (params) {
  let node = (this.node = document.createElement("div"));
  node.className = "stx-marker";
  node.classList.add(params.type);
  node.classList.add("key-development");
  if (params.category) node.classList.add(params.category);
  if (params.subcategory) node.setAttribute("subcategory", params.subcategory);
  if (params.glyph) CIQ.newChild(node, "span", null, params.glyph);

  let visual = CIQ.newChild(node, "div", "stx-visual");
  CIQ.newChild(node, "div", "stx-stem");

  let expand;
  if (params.type == "callout") {
    var content = CIQ.newChild(visual, "div", "stx-marker-content");
    CIQ.newChild(content, "h4", null, params.headline);
    expand = CIQ.newChild(content, "div", "stx-marker-expand");
    CIQ.newChild(expand, "p", null, params.story);
  } else {
    expand = CIQ.newChild(node, "div", "stx-marker-expand");
    CIQ.newChild(expand, "h3", null, params.date);
    CIQ.newChild(
      expand,
      "h4",
      null,
      `<span class="key-development-swatch ${params.category}"></span>${params.categoryName}`
    );
    CIQ.newChild(expand, "h4", null, params.headline);
    CIQ.newChild(expand, "p", null, params.story);
    CIQ.safeClickTouch(expand, function () {
      node.classList.toggle("highlight");
    });
  }

  CIQ.safeClickTouch(visual, function () {
    node.classList.toggle("highlight");
    CIQ.Marker.positionContentVerticalAndHorizontal(node, true);
  });
  this.nodeType = "Simple";
  this.expand = expand;
};

CIQ.inheritsFrom(CIQ.SPGI.KeyDevMarker, CIQ.Marker.Simple, false);

CIQ.UI.Layout.prototype.setAllDevs = function (obj, id) {
  const { e } = obj;
  e.stopPropagation();

  const devs = obj.node.parentNode.querySelectorAll(`[spmi-category="${id}"]`);
  obj.node.closest("cq-item").classList.toggle("ciq-active");
  obj.status = obj.node.closest("cq-item").classList.contains("ciq-active")
    ? true
    : false;
  this.setKeyDevsInBulk(devs, obj);
};

CIQ.UI.Layout.prototype.setKeyDevsInBulk = async function (nodeList, obj) {
  const results = [];
  const stx = this.context.stx;

  for (const node of nodeList) {
    node.parentNode.classList.toggle("ciq-active");
    node.parentNode.querySelector("div")?.classList.toggle("ciq-active");

    if (obj.node.classList.contains("ciq-active")) {
      stx.uiContext.loader.show();
      results.push(
        this.fetchKeyDevs(
          node.attributes["spmi-subcategory"].value,
          node.attributes["spmi-category"].value,
          false
        )
      );
    } else {
      CIQ.Marker.removeByLabelAndCategory(
        stx,
        "KD_" + node.attributes["spmi-category"].value,
        node.attributes["spmi-subcategory"].value
      );
    }

    if (obj.status) {
      node.classList.add("ciq-active");
    } else {
      node.classList.remove("ciq-active");
    }
  }

  if (results.filter((val) => val != undefined).length === 0) {
    stx.dispatch("notification", {
      message: `No Key Developments found for that category within this timerange.`,
    });
  }

  stx.addEventListener("newChart", ({ stx }) => {
    if (Array.isArray(CIQ.SPGI.ActiveElements)) {
      CIQ.SPGI.ActiveElements.forEach((el) => {
        el.toggleOff();
        CIQ.SPGI.ActiveElements.pop();
      });
    }
    CIQ.Marker.removeBySymbol(stx);
  });
};

CIQ.UI.Layout.prototype.setKeyDevs = async function (
  obj,
  id,
  category,
  warning = true
) {
  const { e } = obj;
  const stx = this.context.stx;

  stx.uiContext.loader.show();
  if (e.target.nodeName === "CQ-ITEM") {
    const toggle = e.target.querySelector("span");
    toggle.classList.toggle("ciq-active");
    toggle.querySelector("div")?.classList.toggle("ciq-active");
  }
  e.target.parentNode.classList.toggle("ciq-active");
  e.target.parentNode.querySelector("div")?.classList.toggle("ciq-active");
  e.stopPropagation();

  if (!e.target.parentNode.classList.contains("ciq-active")) {
    CIQ.Marker.removeByLabelAndCategory(stx, "KD_" + category, id);
    if (stx.sp_keydev_markers) stx.sp_keydev_markers[category] = [];
  } else {
    await this.fetchKeyDevs(id, category, warning);
    stx.addEventListener("newChart", ({ stx }) => {
      CIQ.Marker.removeBySymbol(stx);
    });
  }
};

CIQ.UI.Layout.prototype.fetchKeyDevs = async function (
  subcategory,
  category,
  warning = true
) {
  const { symbol, endPoints } = this.context.stx.chart;
  const stx = this.context.stx;
  let ss = moment(endPoints.begin).format("MM/DD/YYYY");
  let ee = moment(endPoints.end).format("MM/DD/YYYY");
  let keydevs = await api.fetchBondKeyDevs(symbol, ss, ee, subcategory);

  stx.sp_keydev_markers = stx.sp_keydev_markers || [];
  stx.sp_keydev_markers[category] = stx.sp_keydev_markers[category] || [];
  if (keydevs.length > 0) {
    keydevs.forEach((entry) => {
      const d = moment(entry.Date).subtract(9, "hours").toDate(); // subtract 9 hours due to backend timestamp error
      if (stx.sp_keydev_markers[category].indexOf(d) != -1) return; // deduplicate for subsequent event ticks

      const datum = {
        headline: entry.headline,
        category: `KD_${category}`,
        categoryName: entry.category,
        subcategory: subcategory,
        story: entry.story,
        glyph: entry.category.substring(0, 1),
        date: d.toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      };

      const mparams = {
        stx,
        label: datum.category,
        xPositioner: "date",
        x: d,
        yPositioner: "above_candle",
        symbol: stx.chart.symbol,
        node: new CIQ.SPGI.KeyDevMarker(datum),
      };

      const marker = new CIQ.Marker(mparams);
      marker.category = subcategory;
      stx.sp_keydev_markers[category].push(d);
    });
    stx.uiContext.loader.hide();
  } else {
    if (warning) {
      stx.dispatch("notification", {
        message: `No key Developmets found for ${symbol} within ${ss} and ${ee}`,
      });
    }
    stx.uiContext.loader.hide();
  }
  stx.draw();
  return keydevs.length;
};

CIQ.Marker.removeByLabelAndCategory = function (stx, category, subcategory) {
  const arr = stx.getMarkerArray("label", category);
  for (let i = 0; i < arr.length; i++) {
    const marker = arr[i];
    if (marker.category === subcategory) {
      stx.removeFromHolder(marker);
      if (marker.stxNodeCreator && marker.stxNodeCreator.remove) {
        marker.stxNodeCreator.remove(marker);
      }
    }
  }
  stx.sp_keydev_markers[category.replace("KD_", "")] = [];
  stx.uiContext.loader.hide();
  stx.draw();
};

CIQ.Marker.removeBySymbol = function (stx) {
  let arr = stx.getMarkerArray("all");
  arr = arr.filter((val) => val?.params?.symbol !== undefined);
  for (let i = 0; i < arr.length; i++) {
    let marker = arr[i];
    if (marker.params.symbol !== stx.chart.symbol) {
      stx.removeFromHolder(marker);
      if (marker.stxNodeCreator && marker.stxNodeCreator.remove) {
        marker.stxNodeCreator.remove(marker);
      }
    }
  }
  document
    .querySelectorAll("div[key_developments] cq-item.ciq-active")
    .forEach((toggle) => toggle.classList.remove("ciq-active"));
  stx.draw();
};

export { CIQ };
