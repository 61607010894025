import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";

import router from "./router";
import store from "./store";
import VueLoading from 'vue-loading-overlay'
import VueNumberFormat from "vue-number-format";
import 'vue-loading-overlay/dist/vue-loading.css';
import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import hollowcandlestick from 'highcharts/modules/hollowcandlestick';
import HighchartsVue from 'highcharts-vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

stockInit(Highcharts)
hollowcandlestick(Highcharts)

const app = createApp(App);
app.use(VueLoading);
app.use(VueNumberFormat, { prefix: "", decimal: ".", thousand: ",", precision: 1 });
app.use(router);
app.use(store);
app.use(HighchartsVue);

app.component('VueDatePicker', VueDatePicker);

app.mount("#app");