const jp = require("jsonpath");
import axios from "axios";

export const _http = (user) => {
  return axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + btoa(user.username + ":" + user.password),
    }
  });
};

export const _x_authenticate = async (user) => {
  const params = {
    username: user.username,
    password: user.password,
  };
  const base = "/authenticate/api/v1/token";
  const queryString = new URLSearchParams(params).toString();
  const url = `${base}?${queryString}`;
  const _xttp = axios.create({
    baseURL: process.env.VUE_APP_XAPI_URL,
  });
  let response = await _xttp.get(url);
  return jp.query(response.data, "$.access_token")[0];
};

export const _xttp = (token) => {
  return axios.create({
    baseURL: process.env.VUE_APP_XAPI_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const _ittp = (token) => {
  const config = {
    baseURL: process.env.VUE_APP_IHS_N6_URL
  }
  if (token) {
    config.headers = {
      Authorization: "Bearer " + token
    }
  }
  return axios.create(config);
};