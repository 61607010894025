<template>
  <div class="container">
    <h2>Search Companies ..</h2>
    <input type="text" placeholder="CIQ ID or Ticker" v-model="query" v-on:keyup.enter="search" />
  </div>
  <General ref="general" />
  <Financials ref="financials" />
  <Transactions ref="transactions" />
  <KeyDevelopments ref="keyDevelopments" />
</template>

<script>
import General from "@/components/dashboard1/General.vue";
import Financials from "@/components/dashboard1/Financials.vue";
import Transactions from "@/components/dashboard1/Transactions.vue";
import KeyDevelopments from "@/components/dashboard1/KeyDevelopments.vue";

export default {
  name: "DashBoard1",
  data: () => {
    return {
      query: "",
    };
  },
  components: {
    General,
    Financials,
    Transactions,
    KeyDevelopments,
  },
  methods: {
    search: async function () {
      let loader = this.$loading.show({
        height: 64,
        width: 64,
        color: "#00ab00",
        backgroundColor: "#4b4b4b",
        isFullPage: true,
        opacity: 0.5,
      });

      try {
        await this.$refs.general.render(this.query);
      } catch (e) {
        alert(e);
      }

      try {
        await this.$refs.financials.render(this.query);
      } catch (e) {
        alert(e);
      }

      try {
        await this.$refs.transactions.render(this.query);
      } catch (e) {
        alert(e);
      }

      try {
        await this.$refs.keyDevelopments.render(this.query);
      } catch (e) {
        alert(e);
      }
      loader.hide();
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}

h3 {
  margin: 40px 0 0;
}

input[type="text"] {
  margin-bottom: 20px;
  padding: 10px;
}
</style>
