<template>
  <div class="container">
    <table>
      <thead>
        <tr>
          <th>Account</th>
          <th v-for="(item, index) in this.periodDate" v-bind:key="index">
            {{ item != undefined && item.isValid != undefined && item.isValid() ? item.format("DD-MMM-YYYY") : item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Revenue</th>
          <td v-for="(item, index) in this.revenue" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>Gross Profit</th>
          <td v-for="(item, index) in this.grossProfit" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>EBITDA</th>
          <td v-for="(item, index) in this.ebitda" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>EBIT</th>
          <td v-for="(item, index) in this.ebit" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>Earning from Cont. Ops.</th>
          <td v-for="(item, index) in this.earningCo" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>Net Income</th>
          <td v-for="(item, index) in this.netIncome" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>Diluted EPS Excl. Extra Items</th>
          <td v-for="(item, index) in this.dilutedEps" v-bind:key="index">
            {{ vueNumberFormat(parseFloat(item), { precision: 2 }) }}
          </td>
        </tr>
        <tr>
          <th>Filing Date</th>
          <td v-for="(item, index) in this.filingDate" v-bind:key="index">
            {{ item != undefined && item.isValid() ? item.format("DD-MMM-YYYY") : "" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
const jp = require("jsonpath");
const moment = require("moment");
import api from '@/api/dashboard1.js'

export default {
  name: "Financials",
  data: () => {
    return {
      revenue: new Array(6),
      grossProfit: new Array(6),
      ebitda: new Array(6),
      ebit: new Array(6),
      earningCo: new Array(6),
      netIncome: new Array(6),
      dilutedEps: new Array(6),
      filingDate: new Array(6),
      periodDate: [
        "IQ_FY-5",
        "IQ_FY-4",
        "IQ_FY-3",
        "IQ_FY-2",
        "IQ_FY-1",
        "IQ_FY",
      ]
    }
  },
  methods: {
    render: async function (q) {
      const r = await api.fetchFinancial(q);
      this.revenue = jp.query(r, "GDSSDKResponse[0]..Row[0]");
      this.grossProfit = jp.query(r, "GDSSDKResponse[1]..Row[0]");
      this.ebitda = jp.query(r, "GDSSDKResponse[2]..Row[0]");
      this.ebit = jp.query(r, "GDSSDKResponse[3]..Row[0]");
      this.earningCo = jp.query(r, "GDSSDKResponse[4]..Row[0]");
      this.netIncome = jp.query(r, "GDSSDKResponse[5]..Row[0]");
      this.dilutedEps = jp.query(r, "GDSSDKResponse[6]..Row[0]");
      this.filingDate = jp.query(r, "GDSSDKResponse[7]..Row[0]").map((x) => moment(x, "MM/DD/YYYY h:mm:ss A"));
      this.periodDate = jp.query(r, "GDSSDKResponse[8]..Row[1]").map((x) => moment(x, "MM/DD/YYYY")); 
    }
  }
};
</script>
<style scoped>
.container {
  width: 1200px;
  margin: 0 auto;
}

.container table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;
}

.container td,
th {
  padding: 10px;
  background-color: #fafafa;
  text-align: left;
  border: 2px solid #ddd;
}

.container td {
  text-align: right;
}

.container thead th {
  text-align: center;
}

.container td {
  background-color: #fefefe;
}

.container tbody th {
  width: 28%;
}

.container tbody td {
  width: 12%;
}
</style>