const jp = require("jsonpath");
import { _xttp } from "./backend.js";

export default {
  fetchCreditRating: async (companyId, token) => {
    let c = companyId.replace("IQ", "");
    let response = await _xttp(token).post(
      "/rxspratings/api/v2/issuer",
      {
        identifierType: "CIQCOMPANYID",
        identifierValues: [c],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.data.error) {
      return response.data;
    }
    return jp.query(response.data, "$.results")[0][0];
  },
  fetchArticleList: async (companyId, token) => {
    let c = companyId.replace("IQ", "");
    let response = await _xttp(token).post(
      "/rxresearch/api/v1/articlelist",
      {
        identifierType: "CiqCompanyId",
        identifierValue: [c],
        articleType: ["FULL"],
        page: 1,
        pageSize: 10,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.data.error) {
      return response.data;
    }
    return jp.query(response.data, "$.results")[0];
  },
  fetchArticleContent: async (articleId, token) => {
    let response = await _xttp(token).post(
      "/rxresearch/api/v1/articlecontent",
      {
        articleId: articleId,
        responseType: "XML",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (response.data.error) {
      return response.data;
    }

    return jp.query(response.data, "$.articleContentResponse.articleXML")[0];
  },
};
