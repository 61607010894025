<template>
  <div class="container">
    <div class="wrap top">
      <input
        type="text"
        placeholder="Search by CIQ ID or Ticker"
        v-model="query"
        v-on:keyup.enter="addPortfolio"
      />
      <input type="button" value="Add" v-on:click="addPortfolio" />
      <input type="button" value="Refresh" v-on:click="refresh" />
    </div>
    <div class="wrap middle">
      <table id="credit-score">
        <thead>
          <tr>
            <th colspan="10">Portfolio S&P Credit Model Score</th>
          </tr>
          <tr>
            <th rowspan="2">Company ID</th>
            <th rowspan="2">Company Name</th>
            <th rowspan="2">Company Type</th>
            <th rowspan="2">Currency</th>
            <th rowspan="2">Industry</th>
            <th colspan="4">Sovereign Capped</th>
            <th rowspan="2" style="width: 20px">&nbsp;&nbsp;</th>
          </tr>
          <tr>
            <th>Score</th>
            <th>Implied 1yr PD</th>
            <th>Implied 3yr PD</th>
            <th>Implied 5yr PD</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in this.portfolio" v-bind:key="index">
            <td style="text-align: left">
              {{
                o.ticker && o.ticker != "Data Unavailable"
                  ? o.ticker
                  : o.companyId
              }}
            </td>
            <td style="text-align: left">{{ o.name }}</td>
            <td style="text-align: left">{{ o.type }}</td>
            <td style="text-align: center">{{ o.currency }}</td>
            <td style="text-align: left">{{ o.industry }}</td>
            <td style="text-align: center">{{ o.creditScore }}</td>
            <td style="text-align: right">
              {{
                isNaN(o.pd1yr)
                  ? "-"
                  : vueNumberFormat(o.pd1yr, { suffix: "%", precision: 4 })
              }}
            </td>
            <td style="text-align: right">
              {{
                isNaN(o.pd3yr)
                  ? "-"
                  : vueNumberFormat(o.pd3yr, { suffix: "%", precision: 4 })
              }}
            </td>
            <td style="text-align: right">
              {{
                isNaN(o.pd5yr)
                  ? "-"
                  : vueNumberFormat(o.pd5yr, { suffix: "%", precision: 4 })
              }}
            </td>
            <td style="text-align: center">
              <i @click="removePortfolio(index)" style="cursor: pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="chartLoaded1" class="wrap middle1" style="margin-top: 50px">
      <BarChart
        :chart-id="revenue - chart"
        :width="1440"
        :height="400"
        :chart-data="chartData1"
        :chart-options="chartOptions1"
      />
    </div>
    <div
      v-if="chartLoaded2 && chartLoaded3"
      class="wrap middle2"
      style="margin-top: 50px"
    >
      <BarChart
        :chart-id="carbon - emission - chart"
        :width="670"
        :height="400"
        :chart-data="chartData2"
        :chart-options="chartOptions2"
        style="float: left; margin-right: 100px"
      />
      <BarChart
        :chart-id="esg - score - chart"
        :width="670"
        :height="400"
        :chart-data="chartData3"
        :chart-options="chartOptions3"
      />
    </div>
  </div>
</template>
<script>
import { _x_authenticate } from "@/api/backend";
import api from "@/api/dashboard3";
import BarChart from "@/components/dashboard3/BarChart.vue";

let moment = require("moment");

export default {
  name: "DashBoard3",
  data: () => {
    return {
      query: "",
      token: "",
      portfolio: [],
      chartLoaded1: false,
      chartLoaded2: false,
      chartLoaded3: false,
      chartOptions1: {
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: "3-Year sales and sales growth",
            font: {
              size: 17,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (label) {
                let realLabel = this.getLabelForValue(label);
                return realLabel.split(":")[1];
              },
            },
          },
          x2: {
            type: "category",
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              callback: function (label) {
                let realLabel = this.getLabelForValue(label);
                let t = realLabel.split(":");
                return t.length == 3 ? t[0] : "";
              },
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "left",
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      },
      chartOptions2: {
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: "Portfolio carbon emission (tonnes CO2e)",
            font: {
              size: 17,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      chartOptions3: {
        responsive: false,
        plugins: {
          title: {
            display: true,
            text: "S&P Global ESG Score",
            font: {
              size: 17,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      chartData1: {
        labels: [],
        datasets: [
          {
            label: "Growth",
            data: [],
            borderColor: "rgb(255, 205, 86)",
            backgroundColor: "rgb(255, 205, 86)",
            type: "line",
            yAxisID: "y1",
          },
          {
            label: "Revenue",
            data: [],
            backgroundColor: "rgb(54, 162, 235)",
            yAxisID: "y",
          },
        ],
      },
      chartData2: {
        labels: [],
        datasets: [
          {
            label: "Scope 1",
            data: [],
            backgroundColor: "rgb(255, 159, 64)",
          },
          {
            label: "Scope 2",
            data: [],
            backgroundColor: "rgb(255, 205, 86)",
          },
          {
            label: "Scope 3 - Upstream",
            data: [],
            backgroundColor: "rgb(75, 192, 192)",
          },
          {
            label: "Scope 3 - Downstream",
            data: [],
            backgroundColor: "rgb(54, 162, 235)",
          },
        ],
      },
      chartData3: {
        labels: [],
        datasets: [
          {
            label: "Environmental",
            data: [],
            backgroundColor: "rgb(75, 192, 192)",
          },
          {
            label: "Social",
            data: [],
            backgroundColor: "rgb(255, 159, 64)",
          },
          {
            label: "Governance",
            data: [],
            backgroundColor: "rgb(54, 162, 235)",
          },
        ],
      },
    };
  },
  mounted: async function () {
    let p = ["IQ91868", "IQ24937", "IQ320396288"];

    try {
      p.forEach((e) => {
        this.portfolio.push({ companyId: e });
      });
      await this.refresh();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    addPortfolio: async function () {
      const user = JSON.parse(localStorage.getItem("user"));
      let ret = await api.validateId(user, this.query);
      if (ret.errMsg != "") {
        alert(this.query + " is not valid ID : " + ret.errMsg);
      } else {
        this.portfolio.push({ companyId: ret.companyId });
      }
    },
    removePortfolio: function (index) {
      this.portfolio.splice(index, 1);
    },
    refresh: async function () {
      let loader = this.$loading.show({
        height: 64,
        width: 64,
        color: "#00ab00",
        backgroundColor: "#4b4b4b",
        isFullPage: true,
        opacity: 0.5,
      });

      try {
        for (var p of this.portfolio) {
          if (p.loaded) continue;
          const user = JSON.parse(localStorage.getItem("user"));
          let ret = await api.fetchCompanyDetail(user, p.companyId);
          Object.assign(p, ret);
          p.loaded = true;
        }
        await this.renderChart();
      } catch (e) {
        console.error(e);
      }

      loader.hide();
    },
    renderChart: async function () {
      if (this.portfolio.length == 0) {
        alert("No portfolio added.");
        return;
      }
      if (this.portfolio.filter((e) => !e.loaded).length > 0) {
        alert("There is no loaded element. Click refresh first.");
        return;
      }
      const user = JSON.parse(localStorage.getItem("user"));
      this.token = await _x_authenticate(user);
      this.renderChart1();
      await this.renderChart2();
      await this.renderChart3();
    },
    renderChart1: async function () {
      this.chartLoaded1 = false;
      try {
        let r = [];
        let g = [];
        let l = [];
        for (var p of this.portfolio) {
          const user = JSON.parse(localStorage.getItem("user"));
          let ret = await api.fetchRevenues(user, p.companyId);
          r = r.concat(ret.revenue);

          g = g.concat(
            ret.growth.map((v, i) => {
              let k =
                p.name +
                ":" +
                moment(ret.perioddate[i], "MM/DD/YYYY").format("YYYY") +
                (i == 1 ? ":" : "");
              return { x: k, y: v };
            })
          );

          // add dummy record to split the line graph
          g.push({
            x: g[g.length - 1].x,
          });

          l = l.concat(
            ret.perioddate.map(
              (e, i) =>
                p.name +
                ":" +
                moment(e, "MM/DD/YYYY").format("YYYY") +
                (i == 1 ? ":" : "")
            )
          );
        }
        this.chartData1.labels = l;
        this.chartData1.datasets[0].data = g;
        this.chartData1.datasets[1].data = r;
        this.chartLoaded1 = true;
      } catch (e) {
        console.error(e);
      }
    },
    renderChart2: async function () {
      this.chartLoaded2 = false;
      try {
        let institutionIds = this.portfolio.map((e) => e.institutionId);
        let ret = await api.fetchCarbonEmissions(this.token, institutionIds, 2020);
        ret.sort(
          (a, b) =>
            institutionIds.indexOf(a.institutionId) -
            institutionIds.indexOf(b.institutionId)
        );
        this.chartData2.labels = ret.map((e) => e.institutionName);
        this.chartData2.datasets[0].data = ret.map((e) => e.tcABSCrbnScope1);
        this.chartData2.datasets[1].data = ret.map((e) => e.tcABSCrbnScope2);
        this.chartData2.datasets[2].data = ret.map((e) => e.tcABSCrbnScope3);
        this.chartData2.datasets[3].data = ret.map(
          (e) => e.tcAbsCrbnScope3Downstream
        );
        this.chartLoaded2 = true;
      } catch (e) {
        console.error(e);
      }
    },
    renderChart3: async function () {
      this.chartLoaded3 = false;
      try {
        let institutionIds = this.portfolio.map((e) => e.institutionId);
        let ret = await api.fetchESGScore(this.token, institutionIds, 2021);
        ret.sort(
          (a, b) =>
            institutionIds.indexOf(a.institutionId) -
            institutionIds.indexOf(b.institutionId)
        );
        this.chartData3.labels = ret.map((e) => e.institutionName);
        this.chartData3.datasets[0].data = ret.map(
          (e) =>
            (e.scoreTypes[0].envScore * e.scoreTypes[0].envScoreWeight) / 100
        );
        this.chartData3.datasets[1].data = ret.map(
          (e) =>
            (e.scoreTypes[0].socialScore * e.scoreTypes[0].socialScoreWeight) /
            100
        );
        this.chartData3.datasets[2].data = ret.map(
          (e) => (e.scoreTypes[0].geScore * e.scoreTypes[0].geScoreWeight) / 100
        );
        this.chartLoaded3 = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
  components: { BarChart }
};
</script>
<style scoped>
.container {
  width: 1440px;
  margin: 0 auto;
  font-family: Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}

.wrap.top {
  padding: 15px;
  text-align: center;
}

.wrap.top input {
  padding: 10px;
}

.wrap.top input[type="text"] {
  width: 595px;
}

.wrap.top input[type="button"] {
  margin-left: 5px;
}

table {
  width: 100%;
  border: 2px solid #444;
  border-collapse: collapse;
}

th {
  background-color: #d6dce4;
}

th,
td {
  border: 1px solid #444;
  padding: 3px;
}
</style>