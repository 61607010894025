<template>
  <div id="nav">
    <div v-if="showMenu">
      <router-link to="/">Home</router-link>
      <router-link to="/dashboard1">Dashboard1</router-link>
      <router-link to="/dashboard2">Dashboard2</router-link>
      <router-link to="/dashboard3">Dashboard3</router-link>
      <router-link to="/dashboard4">Dashboard4</router-link>
      <router-link to="/dashboard5">Dashboard5</router-link>
      <router-link to="/dashboard6">Dashboard6</router-link>
      <router-link to="/dashboard7">Dashboard7</router-link>
      <a @click.prevent="logOut" style="float: right">Log Out</a>
    </div>
    <div v-if="!showMenu">
      <a href="#">&nbsp;</a>
    </div>
  </div>
  <router-view />
</template>

<script>
export default {
  name: "App",
  computed: {
    showMenu() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  overflow: hidden;
  background-color: #333;
}

#nav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  font-size: 17px;
  text-decoration: none;
}

#nav a:hover {
  background-color: #ddd;
  color: black;
}

#nav a.router-link-active {
  background-color: #04aa6d;
  color: white;
}
</style>