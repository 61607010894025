const jp = require("jsonpath");
import { _http, _xttp } from "./backend.js";

const gds_url = "/gdsapi/rest/v3/clientservice.json";

export default {
  validateId: async (user, query) => {
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        {
          function: "GDSP",
          identifier: query,
          mnemonic: "IQ_COMPANY_ID",
        },
      ],
    });
    return {
      errMsg: jp.query(response.data, "GDSSDKResponse[0].ErrMsg"),
      companyId: jp.query(response.data, "GDSSDKResponse[0]..Row[0]")[0],
    };
  },
  fetchCompanyDetail: async (user, companyId) => {
    let inputRequests = [
      "IQ_COMPANY_TICKER",
      "IQ_COMPANY_NAME",
      "IQ_COMPANY_TYPE",
      "IQ_INDUSTRY",
      "IQ_TRADING_CURRENCY",
    ]
      .map((e) => ({
        function: "GDSP",
        identifier: companyId,
        mnemonic: e,
      }))
      .concat(
        [
          "IQ_FN_SOV_IMPL_1YR_CREDIT_SCORE",
          "IQ_FN_SOV_1YR_PD",
          "IQ_FN_SOV_3YR_PD",
          "IQ_FN_SOV_5YR_PD",
        ].map((e) => ({
          function: "GDSP",
          identifier: companyId,
          mnemonic: e,
          properties: { modelId: "FN" },
        }))
      );
    inputRequests.push({
      function: "GDSPV",
      identifier: companyId,
      mnemonic: "BECRS_ENTITY_BASE",
    });

    let response = await _http(user).post(gds_url, {
      inputRequests: inputRequests,
    });

    return {
      ticker: jp.query(response.data, "GDSSDKResponse[0]..Row[0]")[0],
      name: jp.query(response.data, "GDSSDKResponse[1]..Row[0]")[0],
      type: jp.query(response.data, "GDSSDKResponse[2]..Row[0]")[0],
      industry: jp.query(response.data, "GDSSDKResponse[3]..Row[0]")[0],
      currency: jp.query(response.data, "GDSSDKResponse[4]..Row[0]")[0],
      creditScore: jp.query(response.data, "GDSSDKResponse[5]..Row[0]")[0],
      pd1yr:
        parseFloat(jp.query(response.data, "GDSSDKResponse[6]..Row[0]")[0]) *
        100,
      pd3yr:
        parseFloat(jp.query(response.data, "GDSSDKResponse[7]..Row[0]")[0]) *
        100,
      pd5yr:
        parseFloat(jp.query(response.data, "GDSSDKResponse[8]..Row[0]")[0]) *
        100,
      institutionId: parseInt(
        jp.query(
          response.data,
          "GDSSDKResponse[9]..Rows[?(@.Row.indexOf('SNL Institution ID') != -1)].Row[3]"
        )[0]
      ),
    };
  },
  fetchRevenues: async (user, companyId) => {
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        "IQ_TOTAL_REV",
        "IQ_TOTAL_REV_1YR_ANN_GROWTH",
        "IQ_PERIODDATE",
      ].map((e) => ({
        function: "GDSHE",
        identifier: companyId,
        mnemonic: e,
        properties: { PERIODTYPE: "IQ_FY-2", currencyId: "USD" },
      })),
    });

    return {
      revenue: jp
        .query(response.data, "GDSSDKResponse[0]..Row[0]")
        .map((e) => parseFloat(e)),
      growth: jp
        .query(response.data, "GDSSDKResponse[1]..Row[0]")
        .map((e) => parseFloat(e)),
      perioddate: jp.query(response.data, "GDSSDKResponse[2]..Row[0]"),
    };
  },
  fetchCarbonEmissions: async (token, institutionIds, fiscalYear) => {
    let response = await _xttp(token).post(
      "/trucostenvironmental/api/v1/company/environmental",
      {
        institutionId: institutionIds,
        fiscalYear: [fiscalYear],
        fields: [
          "tcABSCrbnScope1",
          "tcABSCrbnScope2",
          "tcABSCrbnScope3",
          "tcAbsCrbnScope3Downstream",
        ],
      }
    );
    return jp.query(response.data, "$.results")[0];
  },
  fetchESGScore: async (token, institutionIds, assessmentYear) => {
    let response = await _xttp(token).post("/esgscores/api/v1/esg/scores", {
      institutionId: institutionIds,
      assessmentYear: [assessmentYear],
      csaScoreTypeName: "Raw",
      fieldGroups: [
        "companyESGScores",
        "dimensionEnvScores",
        "dimensionSocialScores",
        "dimensionGeScores",
      ],
    });
    return jp.query(response.data, "$.results")[0];
  },
};
