<template>
  <div class="container">
    <div class="wrap top">
      <input type="text" placeholder="Search by CIQ ID or Ticker" v-model="query" v-on:keyup.enter="search" />
    </div>
    <div class="wrap middle">
      <table>
        <tr>
          <th class="col-head col-left" />
          <th class="col-head col-right" v-for="(companyName, index) in this.companyNames" v-bind:key="index">
            {{ companyName }} <br />({{ this.symbols[index] }})</th>
        </tr>
        <tr>
          <th class="col-head col-left">Key Strengths</th>
          <td class="col-right" v-for="(report, i) in this.researchReports" :key="i">
            <ul v-if="!report.errMsg">
              <template v-for="(k, j) in report" :key="j">
                <li v-if="k.keyStrength?.trim()">{{ k.keyStrength }}</li>
              </template>
            </ul>
            <div v-else>
              <p>{{ report.errMsg }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <th class="col-head col-left">Key Risks</th>
          <td class="col-right" v-for="(report, i) in this.researchReports" :key="i">
            <ul v-if="!report.errMsg">
              <template v-for="(k, j) in report" :key="j">
                <li v-if="k.keyRisk?.trim()">{{ k.keyRisk }}</li>
              </template>
            </ul>
          </td>
        </tr>
      </table>
      <table id="financials">
        <tr>
          <th class="col-head col-left">Amounts in millions, USD</th>
          <th class="col-head col-right" v-for="(d, i) in this.periodDate" :key="i">FY{{ d?.format("YYYY") }}<br />{{ d?.format("DD-MMM-YYYY") }}</th>
        </tr>
        <tr>
          <th class="col-head col-left">Total Revenue</th>
          <td class="col-right" v-for="(n, i) in this.totalRevenue" :key="i">{{ vueNumberFormat(n) }}</td>
        </tr>
        <tr>
          <th class="col-head col-left">Net Income</th>
          <td class="col-right" v-for="(n, i) in this.netIncome" :key="i">{{ vueNumberFormat(n) }}</td>
        </tr>
        <tr>
          <th class="col-head col-left">Gross Margin %</th>
          <td class="col-right" v-for="(n, i) in this.grossMargin" :key="i">{{ vueNumberFormat(n, { suffix: "%" }) }}</td>
        </tr>
        <tr>
          <th class="col-head col-left">Return on Asset %</th>
          <td class="col-right" v-for="(n, i) in this.returnOnAsset" :key="i">{{ vueNumberFormat(n, { suffix: "%" }) }}</td>
        </tr>
        <tr>
          <th class="col-head col-left">Return on Equity %</th>
          <td class="col-right" v-for="(n, i) in this.returnOnEquity" :key="i">{{ vueNumberFormat(n, { suffix: "%" }) }}</td>
        </tr>
        <tr>
          <th class="col-head col-left">Total Debt/Equity</th>
          <td class="col-right" v-for="(n, i) in this.totalDebtEquity" :key="i">{{ vueNumberFormat(n, { suffix: "%" }) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import api from "@/api/dashboard7";
import { _x_authenticate } from "@/api/backend";

const xpath = require("xpath");
const dom = require("@xmldom/xmldom").DOMParser;
const Buffer = require('buffer').Buffer;
const moment = require("moment");

export default {
  name: "DashBoard7",
  data: () => {
    return {
      query: "",
      companyIds: [],
      symbols: ['Target', 'Peer1', 'Peer2', 'Peer3'],
      companyNames: ['Target', 'Peer1', 'Peer2', 'Peer3'],
      researchReports: [[], [], [], []],
      periodDate: new Array(4),
      totalRevenue: [0, 0, 0, 0],
      netIncome: [0, 0, 0, 0],
      grossMargin: [0, 0, 0, 0],
      returnOnAsset: [0, 0, 0, 0],
      returnOnEquity: [0, 0, 0, 0],
      totalDebtEquity: [0, 0, 0, 0],
    }
  },
  methods: {
    search: async function () {
      let loader = this.$loading.show({
        height: 64,
        width: 64,
        color: "#00ab00",
        backgroundColor: "#4b4b4b",
        isFullPage: true,
        opacity: 0.5,
      });

      const user = JSON.parse(localStorage.getItem("user"));
      const ticker = await api.ticker(user, this.query);
      let symbols = await api.quickComps(user, this.query);


      // add ticker at the first
      symbols.unshift(ticker);

      // reverse order exchange:symbol => symbol:exchange
      symbols = symbols.map(symbol => symbol.includes(":") ? symbol.split(":").reverse().join(":") : symbol);

      this.companyIds = await api.companyIds(user, symbols);
      this.companyNames = await api.companyNames(user, this.companyIds);
      this.symbols = symbols;
      const periodDate = await api.fetchPeriodDates(user, this.companyIds);
      this.periodDate = periodDate.map(d => moment(d, 'MM/DD/YYYY'));

      const token = await _x_authenticate(user);
      this.researchReports.splice(0, this.researchReports.length);

      this.totalRevenue.splice(0, this.totalRevenue.length);
      this.netIncome.splice(0, this.netIncome.length);
      this.grossMargin.splice(0, this.grossMargin.length);
      this.returnOnAsset.splice(0, this.returnOnAsset.length);
      this.returnOnEquity.splice(0, this.returnOnEquity.length);
      this.totalDebtEquity.splice(0, this.totalDebtEquity.length);
      for (const companyId of this.companyIds) {
        const researchReport = await this.fetchRXResearch(companyId, token);
        this.researchReports.push(researchReport);

        const financials = await api.fetchFinancials(user, companyId);
        this.totalRevenue.push(parseFloat(financials.totalRevenue));
        this.netIncome.push(parseFloat(financials.netIncome));
        this.grossMargin.push(parseFloat(financials.grossMargin));
        this.returnOnAsset.push(parseFloat(financials.returnOnAsset));
        this.returnOnEquity.push(parseFloat(financials.returnOnEquity));
        this.totalDebtEquity.push(parseFloat(financials.totalDebtEquity));
      }
      loader.hide();
    },
    fetchRXResearch: async (companyId, token) => {
      let response = await api.fetchArticleList(companyId, token);
      if (response.error) {
        return {
          errMsg: `No full research report exists for companyId: ${companyId}`
        };
      }
      if (response.length == 0) {
        return {
          errMsg: "No research report available."
        }
      }
      const articleId = response[0].articleId;
      response = await api.fetchArticleContent(articleId, token);
      if (response.error) {
        return {
          errMsg: `Failed to read content for articleId: ${articleId}`
        }
      }

      const buffer = Buffer.from(response, "base64");
      const xml = buffer.toString("utf-8");
      const doc = new dom().parseFromString(xml, "text/xml");
      let rows = xpath.select('/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]/section[1]/table[1]/tablerow', doc);
      let contentIndex = 2; // bascially the key strengths / risks content will start from index = 2.
      if (rows.length === 0) {
        rows = xpath.select('/page/ROWSET/ARTICLE/spdoc/analysis/body/section[@name="Credit Highlights"]/table[1]/tablerow', doc);
        contentIndex = 3;
      }
      const keys = [];
      rows.forEach((row, index) => {
        if (index < contentIndex) return;
        const k = {
          keyStrength: row.childNodes[1].textContent,
          keyRisk: row.childNodes[3].textContent
        };
        keys.push(k);
      });
      if (keys.length === 0) {
        return {
          errMsg: "(Key Strength / Risk section does not exist.)"
        }
      }
      return keys;
    },
  }
}
</script>
<style scoped>
.container {
  width: 1440px;
  margin: 0 auto;
  font-family: Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}

input[type="text"] {
  padding: 10px;
}

.wrap.top {
  padding: 8px;
}

.wrap.middle {
  padding: 8px;
  padding-bottom: 0px;
}

.wrap.middle table {
  margin-top: 8px;
}

td ul {
  padding-left: 20px;
  margin: 0;
}

table {
  width: 100%;
  border: 2px solid #444;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #444;
  padding: 3px;
}

.col-head {
  background-color: #d6dce4;
  font-weight: bold;
}

.col-left {
  width: 10%
}

.col-right {
  width: 18%
}

#financials td {
  text-align: right;
}

</style>