<template>
  <div class="container">
  <img alt="logo" src="@/assets/logo.png">
  <p>The S&P Global Market Intelligence API Solutions, using Restful APIs, provides on-demand enterprise data access more securely and with fewer resources than traditional data feeds.</p>
  <p>Access a comprehensive list of financial and non-financial data sets allowing you to pick and choose the data you need without hosting a database.</p>
  <p>Developers can leverage secure web services to dynamically access S&P Global datasets, and curated third-party datasets, or can interact directly with the web services via REST/JSON.</p>
  <p>This website is for demonstration with the S&P Global  API dataset written in Vue 3.</p>
  </div>
</template>
<script>
export default {
  name: "HomeScreen"
}
</script>
<style scoped>
  .container {
    text-align: center;
  }
</style>