<template>
  <div id="chartDashboard6">
    <cq-context class="ciq-night">
      <div class="ciq-nav full-screen-hide" role="navigation">
        <cq-clickable role="button" class="symbol-search" cq-selector="cq-lookup-dialog" cq-method="open">
          <span class="ciq-lookup-icon"></span>
          <cq-tooltip>Symbol Search</cq-tooltip>
        </cq-clickable>
        <cq-clickable role="button" class="symbol-search" cq-selector="cq-lookup-dialog" cq-method="open"
          comparison="true">
          <span class="ciq-comparison-icon"></span>
          <cq-tooltip>Add Comparison</cq-tooltip>
        </cq-clickable>
        <cq-side-nav cq-on="sidenavOn">
          <div class="icon-toggles ciq-toggles">
            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-CH" cq-member="crosshair">
                <span></span>
                <cq-tooltip>Crosshair<span> (Alt + \)</span></cq-tooltip>
              </cq-toggle>
              <cq-menu class="ciq-menu toggle-options collapse">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="crosshair">Hide Heads-Up Display<span
                      class="ciq-radio"><span></span></span></cq-item>
                  <cq-item cq-member="headsUp-static">Show Heads-Up Display<span
                      class="ciq-radio"><span></span></span></cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>
            <cq-info-toggle-dropdown>
              <cq-toggle class="ciq-HU" cq-member="headsUp">
                <span></span>
                <cq-tooltip>Info</cq-tooltip>
              </cq-toggle>
              <cq-menu class="ciq-menu toggle-options collapse tooltip-ui">
                <span></span>
                <cq-menu-dropdown>
                  <cq-item cq-member="headsUp-floating">Show Tooltip<span
                      class="ciq-radio"><span></span></span></cq-item>
                </cq-menu-dropdown>
              </cq-menu>
            </cq-info-toggle-dropdown>
          </div>
        </cq-side-nav>
        <div class="ciq-menu-section">
          <div class="ciq-dropdowns">
            <cq-menu class="ciq-menu ciq-display collapse">
              <cq-clickable cq-tooltip-activator="" stxbind="Layout.chartType" ciq-no-icon-text="Display">
                <span ciq-menu-icon=""></span>
                <cq-tooltip></cq-tooltip>
              </cq-clickable>
              <cq-menu-dropdown>
                <cq-menu-dropdown-section class="chart-types">
                  <cq-heading>Chart Types</cq-heading>
                  <cq-menu-container cq-name="menuChartStyle"></cq-menu-container>
                </cq-menu-dropdown-section>
                <cq-menu-dropdown-section class="chart-aggregations advanced-ui">
                  <cq-separator></cq-separator>
                  <cq-heading>Aggregated Types</cq-heading>
                  <cq-menu-container cq-name="menuChartAggregates"></cq-menu-container>
                </cq-menu-dropdown-section>
              </cq-menu-dropdown>
            </cq-menu>
            <cq-menu class="ciq-menu stx-markers collapse">
              <span>Key Developments</span>
              <cq-menu-dropdown style="width: 300px">
                <div class="markers-ui" style="display: inherit" key_developments>
                  <cq-item class="spmi-header" stxsetget="Layout.AllDevs('C5')">
                    <cq-heading>Potential Red Flags/Distress Indicators</cq-heading>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T24', 'C5')" spmi-subcategory="T24" spmi-category="C5">
                    <span>Account Issues/SEC Inquiries</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T150', 'C5')" spmi-subcategory="T150" spmi-category="C5">
                    <span>Auditor Changes</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T59', 'C5')" spmi-subcategory="T59" spmi-category="C5">
                    <span>Auditor Going Concern Doubts</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T74','C5')" spmi-subcategory="T74" spmi-category="C5"><span>Debt
                      Defaults</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T61','C5')" spmi-subcategory="T61"
                    spmi-category="C5"><span>Delayed
                      Earnings Announcements</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T11','C5')" spmi-subcategory="T11"
                    spmi-category="C5"><span>Delayed
                      SEC Filings</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T21','C5')" spmi-subcategory="T21"
                    spmi-category="C5"><span>Discontinued Operations/Downsizings</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T224','C5')" spmi-subcategory="T224"
                    spmi-category="C5"><span>Halt/Resume of Operations - Unusual Events</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T73','C5')" spmi-subcategory="T73"
                    spmi-category="C5"><span>Impairments/Write Offs</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T44','C5')" spmi-subcategory="T44"
                    spmi-category="C5"><span>Labor-Related Announcements</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T25','C5')" spmi-subcategory="T25"
                    spmi-category="C5"><span>Lawsuits
                      &amp; Legal Issues</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T206','C5')" spmi-subcategory="T206"
                    spmi-category="C5"><span>Regulatory Authority – Compliance</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T207','C5')" spmi-subcategory="T207"
                    spmi-category="C5"><span>Regulatory Authority – Enforcement Actions</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T43','C5')" spmi-subcategory="T43"
                    spmi-category="C5"><span>Restatements of Operating Results</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-separator></cq-separator>
                  <cq-item class="spmi-header" stxsetget="Layout.AllDevs('C4')">
                    <cq-heading>Listing/Trading Related</cq-heading>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T12','C4')" spmi-subcategory="T12"
                    spmi-category="C4"><span>Delistings</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T92','C4')" spmi-subcategory="T92" spmi-category="C4"><span>End of
                      Lock-Up Period</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T57','C4')" spmi-subcategory="T57"
                    spmi-category="C4"><span>Exchange
                      Changes</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T95','C4')" spmi-subcategory="T95" spmi-category="C4"><span>Index
                      Constituent Adds</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T75','C4')" spmi-subcategory="T75" spmi-category="C4"><span>Index
                      Constituent Drops</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                  <cq-item stxsetget="Layout.KeyDevs('T58','C4')" spmi-subcategory="T58" spmi-category="C4"><span>Ticker
                      Changes</span>
                    <span class="ciq-switch"><span></span></span>
                  </cq-item>
                </div>
              </cq-menu-dropdown>
            </cq-menu>
            <cq-menu class="ciq-menu ciq-period">
              <span><cq-clickable stxbind="Layout.periodicity">10 Sec</cq-clickable></span>
              <cq-menu-dropdown>
                <cq-menu-container cq-name="menuPeriodicity"></cq-menu-container>
              </cq-menu-dropdown>
            </cq-menu>
          </div>
        </div>
      </div>
      <div class="ciq-chart-area" role="main">
        <div class="ciq-chart">
          <cq-message-toaster defaultdisplaytime="10" defaulttransition="slide"
            defaultposition="top"></cq-message-toaster>
          <div class="chartContainer" style="width:100%; height:800px; position:relative;">
            <table class="hu-tooltip">
              <caption>Tooltip</caption>
              <tbody>
                <tr hu-tooltip-field="" class="hu-tooltip-sr-only">
                  <th>Field</th>
                  <th>Value</th>
                </tr>
                <tr hu-tooltip-field="DT">
                  <td class="hu-tooltip-name">Date/Time</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="Close">
                  <td class="hu-tooltip-name">Mid</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="Open">
                  <td class="hu-tooltip-name">h-Mid</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="High">
                  <td class="hu-tooltip-name">Ask</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
                <tr hu-tooltip-field="Low">
                  <td class="hu-tooltip-name">Bid</td>
                  <td class="hu-tooltip-value"></td>
                </tr>
              </tbody>
            </table>
            <cq-chart-title cq-marker>
              <cq-clickable role="button" cq-selector="cq-lookup-dialog" cq-method="open" title="Symbol Search">
                <cq-symbol />
              </cq-clickable>
              <cq-symbol-description />
              <cq-chart-price>
                <cq-current-price cq-animate></cq-current-price>
                <cq-change>
                  <div class="ico"></div>
                  <cq-todays-change></cq-todays-change><cq-todays-change-pct></cq-todays-change-pct>
                </cq-change>
              </cq-chart-price>

            </cq-chart-title>
            <cq-loader></cq-loader>
          </div>
        </div>
      </div>
    </cq-context>
  </div>
</template>
<script>
import { markers, quoteFeed, symbolLookupBase } from 'chartiq/js/standard'
import 'chartiq/js/advanced'
import 'chartiq/js/addOns'
import { CIQ } from 'chartiq/js/components'
import 'chartiq/js/thirdparty/custom-elements.min.js'
import getDefaultConfig from "chartiq/js/defaultConfiguration.js";
import api from "@/api/dashboard6";
import "@/components/dashboard6/key-developments.js"

// eslint-disable-next-line
let moment = require("moment");

export default {
  name: 'DasbBoard6',
  data: () => {
    return {
      isin: "US912810RQ31",
      companyId: "IQ20499240",
      companyName: "United States Department of The Treasury",
      after: null,
      lastPrice: null
    }
  },
  mounted: async function () {
    let self = this;

    // Remove existing lookup dialogs 
    const divs = document.querySelectorAll('.cq-dialogs');
    for (const div of divs) {
      div.remove();
    }

    CIQ.activateImports(markers, quoteFeed, symbolLookupBase);
    CIQ.ChartEngine.Driver.Lookup.ChartIQ = function (exchanges) {
      this.exchanges = exchanges;
    }
    CIQ.inheritsFrom(
      CIQ.ChartEngine.Driver.Lookup.ChartIQ,
      CIQ.ChartEngine.Driver.Lookup
    );
    CIQ.ChartEngine.Driver.Lookup.ChartIQ.prototype.acceptText = async function (
      text,
      filter,
      maxResults,
      cb
    ) {
      let response = await api.fetchBondSymbols(text);
      self.companyId = response.companyId;
      self.companyName = response.companyName;
      let ret = [];
      ret.push({
        display: [text, response.securityName, response.companyName],
        data: {
          symbol: text,
          name: response.securityName,
          exchDisp: response.companyName,
        },
      })
      cb(ret);
    };

    let apikey = await api.fetchN6APIKey();

    // eslint-disable-next-line
    let fetchHist = async (symbol, startDate, endDate, params, cb, isInit) => {
      const dateDiff = (endDate - startDate) / (1000 * 60 * 60 * 24);
      const s = startDate.toISOString();
      const e = endDate.toISOString();
      let quotes;
      if (dateDiff < 3) {
        quotes = await api.fetchHistoricalBondPricesI(apikey, symbol, s);
      } else {
        quotes = await api.fetchHistoricalBondPricesD(apikey, symbol, s, e);
      }

      if (quotes.length > 0) {
        if (isInit) {
          self.lastPrice = quotes[quotes.length - 1].Close;
        }
      } else {
        CIQ.UI.getMyContext(document.getElementById("chartDashboard6"), true).stx.dispatch("notification", { message: `No more price data available within ${moment(startDate).format("MM/DD/YYYY")} and ${moment(endDate).format("MM/DD/YYYY")}` });
      }

      params.stx.chart.symbolDisplay = self.companyName;
      cb({
        quotes: quotes,
        moreAvailable: quotes.length > 0
      });
    }

    let quotefeed = {
      // eslint-disable-next-line
      fetchInitialData: async function (symbol, startDate, endDate, params, cb) {
        console.log("Asking for initial data...");
        fetchHist(symbol, startDate, endDate, params, cb, true);
      },
      // eslint-disable-next-line
      fetchUpdateData: async function (symbol, startDate, params, cb) {
        console.log("Asking for update data...");
        let ret = await api.fetchRealtimeBondPrices(apikey, symbol, self.after);
        self.after = ret.after;
        if (ret.quote.length > 0) {
          if (self.lastPrice) {
            ret.quote[ret.quote.length - 1].Open = self.lastPrice;
          }
          self.lastPrice = ret.quote[ret.quote.length - 1].Close;
        }
        cb({
          quotes: ret.quote
        });
        return;
      },
      // eslint-disable-next-line
      fetchPaginationData: async function (symbol, startDate, endDate, params, cb) {
        console.log("Asking for pagination data...");
        fetchHist(symbol, startDate, endDate, params, cb, false);
      }
    };

    const config = getDefaultConfig({
      quoteFeed: quotefeed,
    });
    config.chartId = "chartDashboard6";
    config.initialSymbol = {
      symbol: "US912810RQ31",
      name: "United States of America 2,5% 16/46 Bond",
      exchDisp: "OTC"
    };
    config.menuPeriodicity = [
      { type: "item", label: "10 Sec", cmd: "Layout.setPeriodicity(1, 10, 'second')", value: { period: 1, interval: 10, timeUnit: 'second' } },
      { type: "item", label: "30 Sec", cmd: "Layout.setPeriodicity(3, 10, 'second')", value: { period: 3, interval: 10, timeUnit: 'second' } },
      { type: "separator", },
      { type: "item", label: "60 Sec", cmd: "Layout.setPeriodicity(6, 10, 'second')", value: { period: 6, interval: 10, timeUnit: 'second' } },
      { type: "item", label: "300 Sec", cmd: "Layout.setPeriodicity(30, 10, 'second')", value: { period: 30, interval: 10, timeUnit: 'second' } },
      { type: "separator", },
      { type: "item", label: "1 day", cmd: "Layout.setPeriodicity(1, 1, 'day')", value: { period: 1, interval: 1, timeUnit: 'day' } },
    ];
    config.quoteFeeds = [{
      quoteFeed: quotefeed,
      behavior: { refreshInterval: 10, bufferSize: 200 }
    }];
    const stxx = config.createChart(document.getElementById("chartDashboard6"));
    stxx.cleanupGaps = "carry";
    stxx.loadChart();
  }
}
</script>
<style>
@import '../../node_modules/chartiq/css/stx-chart.css';

cq-item.spmi-header cq-heading {
  margin: -2px 0 3px;
  padding: 0;
  display: inline-block;
  padding-top: 7px;
}

.stx-marker.key-development>span {
  pointer-events: none;
  z-index: 50;
  position: absolute;
  display: inline-block;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.stx-marker.key-development .stx-visual {
  width: 20px;
  height: 20px;
  background: rgb(135, 135, 135);
}

.stx-marker.key-development .stx-visual:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  height: 25px;
  width: 25px;
  opacity: 0;
  transition: opacity 0.2s 0s, border 0.2s 0s, width 0.2s 0s, height 0.2s 0s,
    top 0.2s 0s, left 0.2s 0s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 50%;
}

.stx-marker.key-development .stx-marker-expand {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 350px;
  text-align: left;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transition: opacity 0.5s 0s;
  pointer-events: none;
  padding: 10px;
  border: none;
  color: #020202;
}

.stx-marker.highlight.key-development .stx-marker-expand {
  width: 350px;
  max-height: 200px;
  opacity: 1;
  z-index: 100;
  pointer-events: visible;
}

.stx-marker.key-development .stx-marker-expand h3 {
  margin-top: 0;
  font-style: italic;
}

.stx-marker.key-development .stx-marker-expand h4 {
  font-size: 1.1em;
}

.stx-marker.key-development .stx-marker-expand p {
  font-size: 1em;
  line-height: 1.2em;
}


.key-development-swatch {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 10px;
}

.stx-marker.KD_C4 .stx-visual,
.key-development-swatch.KD_C4 {
  background: rgb(135, 135, 135);
}

.stx-marker.KD_C5 .stx-visual,
.key-development-swatch.KD_C5 {
  background: rgb(178, 19, 27);
}
</style>