<template>
  <div class="root">
    <ul>
      <li><b>Name</b> {{ this.name }}</li>
      <li>
        <b>Website</b> <a href="{{this.website}}">{{ this.website }}</a>
      </li>
      <li><b>Ticker</b> {{ this.ticker }}</li>
      <li><b>Year Founded</b> {{ this.yearFounded }}</li>
      <li>
        <b>Primary Industry Classfication</b>
        {{ this.primaryIndustrClassification }}
      </li>
      <li><b>Head Quarter</b> {{ this.address }}</li>
      <li><b>Business Description</b> {{ this.businessDescription }}</li>
    </ul>
  </div>
</template>

<script>
const jp = require("jsonpath");
import api from '@/api/dashboard1.js'

export default {
  name: "General",
  data: () => {
    return {
      name: "",
      website: "",
      ticker: "",
      yearFounded: "",
      primaryIndustrClassification: "",
      businessDescription: "",
      address: ""
    }
  },
  methods: {
    render: async function (q) {
      const r = await api.fetchGeneral(q);
      const result = jp.query(r, "$..Row[0]");
      this.name = result[0];
      this.website = result[1];
      this.ticker = result[2];
      this.yearFounded = result[3];
      this.primaryIndustrClassification = result[4];
      this.businessDescription = result[5];
      this.address = result[6];
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.root {
  width: 1200px;
  margin: 0 auto;
}

ul {
  list-style: none;
  width: 50px 0;
  padding: 0;
  background-color: #fafafa;
  border-radius: 5px;
  border: 2px solid #ddd;
}

li {
  text-align: left;
  padding: 10px;
  border-bottom: 2px solid #ddd;
}

li:nth-last-of-type(1) {
  border-bottom: none;
}

a {
  color: #42b983;
}

input[type="text"] {
  margin-bottom: 20px;
  padding: 10px;
}
</style>
