<template>
  <div class="container">
    <div class="dp__input_wrap">
      <input class="dp__input" type="text" placeholder="CIQ ID or Ticker" v-model="query" v-on:keyup.enter="querySubmitted" />
    </div>
    <VueDatePicker v-model="endDate" :format="pickerFormat" @update:model-value="pickerUpdated" position="left">
    </VueDatePicker>
    <div class="chart_wrap">
      <highcharts :constructor-type="'stockChart'" :options="chartOptions" ref="highchartRef"></highcharts>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import api from "@/api";
import { ref } from "vue";
let moment = require("moment");

export default {
  name: "DashBoard4",
  setup: () => {
    const highchartRef = ref(null);

    let user = JSON.parse(localStorage.getItem("user"));
    const _http = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-type": "application/json",
        Authorization: "Basic " + btoa(user.username + ":" + user.password),
      },
      timeout: 10000,
    });

    const pickerFormat = (d) => {
      let s = moment(d).subtract(2, 'years').format('MM/DD/YYYY');
      let e = moment(d).format('MM/DD/YYYY');
      return "Select the end date. Will fetch 2-year history: " + s + "~" + e;
    }

    return {
      highchartRef,
      _http,
      pickerFormat
    }
  },

  methods: {
    refresh: async function () {
      let loader = this.$loading.show({
        height: 64,
        width: 64,
        color: "#00ab00",
        backgroundColor: "#4b4b4b",
        isFullPage: true,
        opacity: 0.5,
      });

      try {
        let labels = await api.fetchChartLabels(this._http, this.query);
        if (labels.errMsg != "") {
          alert(this.query + " is not valid identifier : " + labels.errMsg);
          return;
        }

        this.chartOptions.title.text = labels.name + ' (' + labels.ticker + ')';
        this.chartOptions.series[0].name = labels.ticker;

        let s = moment(this.endDate).subtract(2, 'years').format('MM/DD/YYYY');
        let e = moment(this.endDate).format('MM/DD/YYYY');
        let ohlc = await api.fetchChartPrices(this._http, this.query, s, e);
        this.chartOptions.series[0].data = ohlc;

        let r = await api.fetchVolumesAndKeyDevs(this._http, this.query, s, e);
        let volumes = r.volumes;
        this.chartOptions.series[1].data = volumes;

        let k = r.keydevs;
        let keydevs = [];
        k.forEach((e) => {
          const d = new Date(e.date);
          const exists = keydevs.find(ee => ee.x.getTime() == d.getTime());
          if (!exists) {
            keydevs.push({x: d, title: ' ', text: e.headline});
          } else {
            exists.text += "<br>" + e.headline;
          }
        });
        this.chartOptions.series[2].data = keydevs;

        const xAxis = this.highchartRef.chart.xAxis[0];
        s = ohlc[ohlc.length - 60][0];
        e = ohlc[ohlc.length - 1][0];
        xAxis.setExtremes(s, e);
      } catch (e) {
        console.error(e);
      } finally {
        loader.hide();
      }
    },
    pickerUpdated: async function () {
      this.refresh();
    },
    querySubmitted: async function () {
      this.refresh();
    }
  },

  data: () => {
    return {
      query: "IQ24937",
      ticker: '',
      name: '',
      endDate: new Date(),
      chartOptions: {
        title: {
          text: ''
        },

        xAxis: {
          type: 'datetime'
        },

        yAxis: [{
          height: '60%'
        }, {
          top: '65%',
          height: '35%',
          offset: 0,
          lineWidth: 2
        }],

        rangeSelector: {
          inputEnabled: false
        },

        chart: {
          height: 600
        },

        series: [{
          name: '',
          type: 'hollowcandlestick',
          data: [],
          tooltip: {
            valueDecimals: 2
          },
          id: 'price'
        }, {
          type: 'column',
          name: 'Volume',
          data: [],
          yAxis: 1
        }, {
          type: 'flags',
          onSeries: 'price',
          shape: 'squarepin',
          data: [],
          width: 10
        }]
      }
    }
  },
  mounted: async function () {
    await this.refresh();
  }
}
</script>
<style scoped>
.chart_wrap {
  margin-top: 50px;
}
</style>