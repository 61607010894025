import { _http } from "./backend.js";

const gds_url = "/gdsapi/rest/v3/clientservice.json";

export default {
  fetchGeneral: async (q) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_COMPANY_NAME",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_COMPANY_WEBSITE",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_COMPANY_TICKER",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_YEAR_FOUNDED",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_PRIMARY_INDUSTRY",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_BUSINESS_DESCRIPTION",
        },
        {
          function: "GDSP",
          identifier: q,
          mnemonic: "IQ_COMPANY_ADDRESS",
        },
      ],
    });
    return response.data;
  },
  fetchFinancial: async (q) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_TOTAL_REV",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_GP",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_EBITDA",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_EBIT",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_EARNING_CO",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_NI",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_DILUT_EPS_EXCL",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_FILINGDATE_IS",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_PERIODDATE",
          properties: {
            MetaDataTag: "PeriodDate",
            PeriodType: "IQ_FY-5",
            RestatementTypeId: "LC",
          },
        },
      ],
    });
    return response.data;
  },
  fetchTransactionList: async (q) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_TRANSACTION_LIST",
          properties: {
            startRank: "1",
            endRank: "10",
          },
        },
      ],
    });
    return response.data;
  },
  fetchTransactionDetails: async (transactionIds) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let inputRequests = [];
    for (var transactionId of transactionIds) {
      inputRequests = inputRequests.concat([
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_ANN_DATE",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_CLOSED_DATE",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_TRANSACTION_TYPE",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_BUYERNAME",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_TARGETNAME",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_STATUS",
        },
        {
          function: "GDSP",
          identifier: transactionId,
          mnemonic: "IQ_TR_TOTALVALUE",
        },
      ]);
    }
    let response = await _http(user).post(gds_url, {
      inputRequests: inputRequests,
    });
    return response.data;
  },
  fetchKeyDevelopmentList: async (q) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let response = await _http(user).post(gds_url, {
      inputRequests: [
        {
          function: "GDSHE",
          identifier: q,
          mnemonic: "IQ_KEY_DEV_ID",
          properties: {
            startDate: "-14D",
          },
        },
      ],
    });
    return response.data;
  },
  fetchKeyDevelopmentDetails: async (keyDevelopmentIds) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let inputRequests = [];
    for (var keyDevId of keyDevelopmentIds) {
      inputRequests = inputRequests.concat([
        {
          function: "GDSP",
          identifier: keyDevId,
          mnemonic: "IQ_KEY_DEV_DATE",
        },
        {
          function: "GDSP",
          identifier: keyDevId,
          mnemonic: "IQ_KEY_DEV_HEADLINE",
        },
        {
          function: "GDSP",
          identifier: keyDevId,
          mnemonic: "IQ_KEY_DEV_TYPE",
        },
        {
          function: "GDSP",
          identifier: keyDevId,
          mnemonic: "IQ_KEY_DEV_SOURCE",
        },
      ]);
    }
    let response = await _http(user).post(gds_url, {
      inputRequests: inputRequests,
    });
    return response.data;
  }
};
