<template>
  <div id="login-form-wrap">
    <h1>GDS API demo page</h1>
    <h2>Login using your GDS API credential</h2>
    <form id="login-form" @submit.prevent="onSubmit">
      <p>
        <input
          type="text"
          name="username"
          v-model="input.username"
          placeholder="Username"
          required
        />
      </p>
      <p>
        <input
          type="password"
          name="password"
          v-model="input.password"
          placeholder="Password"
        />
      </p>
      <p>
        <input type="submit" value="Login" />
      </p>
    </form>    
  </div>
</template>
<script>
export default {
  name: "LoginWindow",
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created: function () {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    onSubmit: function () {
      let user = {
        username: this.input.username,
        password: this.input.password,
      };

      let loader = this.$loading.show({
        height: 64,
        width: 64,
        color: "#00ab00",
        backgroundColor: "#4b4b4b",
        isFullPage: true,        
        opacity: 0.5
      });   

      this.$store.dispatch("auth/login", user).then(
        () => {          
          this.$router.push("/");
          loader.hide();
        },
        () => {
          loader.hide();
          alert("Login failed: Please check username or password");
        }
      );
    },
  },
};
</script>
<style scoped>
#login-form-wrap {
  background-color: #fff;
  width: 35%;
  margin: 60px auto;
  text-align: center;
  padding: 20px 0 0;
}

#login-form {
  padding: 0 60px;
}

input[type="text"], input[type="password"] {  
  padding: 0 0 0 10px;
  color: #8a8b8e;
  border: 1px solid #c2c0ca;
  font-style: normal;
  font-size: 16px; 
}

input[type="submit"] {
  border: none;
  font-weight: bold;
  background-color: #04aa6d;
  color: #fff;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #ddd;
  color: black;
}

input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  outline: none;
  border-radius: 4px;
  display: inline-block;  
  background: none;
  box-sizing: border-box;
}
</style>