import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status : {loggedIn: true}, user } : { status : {loggedIn: false}, user: null }

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login: async ({ commit }, user) => {
      let url = "/gdsapi/rest/v3/clientservice.json";
      let headers = {
        'Content-type': 'application/json',
        'Authorization': 'Basic ' + btoa(user.username + ':' + user.password)
      }      
      let response = await axios.post(url, { inputRequests: []}, { headers : headers });                  
      if (response.status == 200) {
        localStorage.setItem('user', JSON.stringify({ username: user.username, password: user.password }));
        commit('loginSuccess', user);
        return Promise.resolve(user);
      } else {        
        commit('loginFailure');
        return Promise.reject(null);
      }      
    },
    logout: async ({ commit }) => {
      localStorage.removeItem('user');
      commit('logout');
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    }

  }
}